// mixins/fetchOrganizationsMixin.js
import { showErrorMessage } from "@/utils";
import { fetchOrganizations } from "@/api/organizations";

// 混入直接取用上下文跟共用
export default {
  methods: {
    async fetchOrganizations() {
      try {
        const response = await fetchOrganizations();
        this.organizations = response.data;
        if (this.organizations.length > 0) {
          this.selectedOrganization = this.organizations[0].id;
        }
      } catch (error) {
        showErrorMessage("Error", "Error fetching organizations:");
      }
    }
  }
}
