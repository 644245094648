<template>
  <div class="w-full flex justify-center">
    <div class="w-full sm:w-1/2 h-3/4 rounded p-6">
      <form id="update-form" method="POST" @submit.prevent="handleUpdate"
        class="flex flex-col px-10 py-6 items-center rounded-xl">
        <h2 class="text-blue-700 text-3xl font-semibold my-4">User Update</h2>
        <label for="username" class="text-sm">Username:</label>
        <input type="text" name="username" autocomplete="off" v-model="update_user.username"
          class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
          required>
        <br>
        <label for="email">Email:</label>
        <input type="email" name="email" v-model="update_user.email"
          class="text-center h-8 w-full rounded-md border border-slate-300 text-sm  bg-transparent outline-blue-600 shadow-sm mb-4"
          required>
        <br>
        <label for="organization">Training Cert ID:</label>
        <div class="text-sm flex w-full justify-between mb-4 mt-4">
          <div class="w-full pr-2">
            <input id="trainingCertId" type="text" name="training_cert"
              class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm"
              v-model="training_cert" :disabled="isDisabled">
          </div>
        </div>
        <div>
          <label for="organization">Organization:</label>
          <div v-if="userRole == 'SUPER'">
            <select v-model="selectedOrganization" class="text-sm mx-1 py-2 px-4 mb-4" required>
              <option disabled value=""></option>
              <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
                {{ organization.name }}
              </option>
            </select>
          </div>
          <div v-else>
            <span class="text-sm">{{ update_user.organization_name }}</span>
          </div>
        </div>
        <label for="role">Role:</label>
        <div v-if="filteredRoles">
          <div class="role-selector flex">
            <div v-for="role in filteredRoles" :key="role" class="role-option text-sm mx-1 px-4">
              <input type="radio" :id="role" :value="role" v-model="update_user.role">
              <label :for="role">{{ role }}</label>
            </div>
          </div>
        </div>
        <div v-else>
          <span class="text-sm"> {{ update_user.role }}</span>
        </div>
        <div class="grid grid-cols-2 gap-4 justify-center w-full">
          <button type="submit"
            class="bg-blue-700 h-10 cursor-pointer text-white rounded-md hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm">Update</button>
          <button type="button" @click="goBack"
            class="bg-gray-600 h-10 cursor-pointer text-white rounded-md hover:bg-red-600 hover:outline outline-2 outline-yellow-300 outline-offset-2 text-sm">Back</button>
        </div>
      </form>
    </div>
  </div>
</template>  
<script>
import { getUser, updateUser, fetchTrainingCertId } from '@/api/users.js';
import { showErrorMessage, showSuccessMessage } from '@/utils';
import { ROLES } from "@/constants.js";
import fetchOrganizationsMixin from '@/mixins/fetchOrganizationsMixin';

export default {
  mixins: [fetchOrganizationsMixin],
  data() {
    return {
      isDisabled: false,
      selectedOrganization: null,
      organizations: [],
      organization_name: "",
      training_cert: "",
      userRole: "",
      roles: ROLES,
      update_user: {
        username: '',
        email: '',
        training_cert: '',
        organization: '',
        organization_name: '',
        role: ''
      },
    }
  },
  computed: {
    currentUserId() {
      return this.$store.state.user.id;
    },
    filteredRoles() {
      if (this.userRole === "SUPER") {
        return this.roles;
      }

      return this.roles.filter(role => role !== 'ADMIN' && role !== 'SUPER');
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async handleUpdate() {
      let requiresTrainingCertUpdate = true;
      let trainingCert = null;
      this.update_user.organization = this.selectedOrganization;
      this.isLoading = true;
      try {
        if (this.training_cert) {
          // 證書相同則不更新
          if (this.update_user.training_cert == this.training_cert) {
            requiresTrainingCertUpdate = false;
          } else {
            //取得證書
            const response = await fetchTrainingCertId(this.training_cert);
            trainingCert = response.data;
            // 如果已經有占用
            if (trainingCert.is_assigned) {
              showErrorMessage("更新失敗", "TrainingCertId 已被佔用。");
              return;
            }
          }
        }
      } catch (error) {
        showErrorMessage("更新 TrainingCertId 失敗", "更新過程中發生錯誤。");
        return;
      } finally {
        this.isLoading = false;
      }

      this.isLoading = true;
      try {
        //更新證書
        if (requiresTrainingCertUpdate) {
          //證書清除
          if (!this.training_cert) {
            if (this.update_user.training_cert) {
              this.update_user.training_cert = null;
            }
          } else {
            this.update_user.training_cert = trainingCert.id;
          }
        }

        const response = await updateUser(this.update_user);
        if (response.data.success) {
          const userData = response.data.data;
          if (this.currentUserId == userData.id) {
            userData.organization = userData.organization_name;
            this.$store.commit('updateUser', userData);
          }
          showSuccessMessage("更新用戶成功", "您已成功更新用戶信息！");
          this.$router.push({ name: 'UserList' });
        } else {
          showErrorMessage("更新失敗", "更新過程中發生錯誤。");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          // 相同姓名(不能相同)
          if ("username" in error.response.data) {
            showErrorMessage("更新失敗", error.response.data["username"][0]);
          } else {
            showErrorMessage("更新失敗", "更新過程中發生錯誤。");
          }
        } else {
          showErrorMessage("更新失敗", "發生未知錯誤。");
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
  ,
  async mounted() {
    const user = this.$store.state.user;
    this.userRole = user.role;
    this.isLoading = true;
    try {
      //讀取組織
      await this.fetchOrganizations();
      //取得更新使用者資訊
      const userId = this.$route.params.userId;
      const response = await getUser(userId);
      this.update_user = response.data;
      //設定欄位資料
      this.training_cert = this.update_user.training_cert;
      //設定Training Cert ID(非SUPER則不能再變更)
      console.log(this.update_user);
      if (this.training_cert && this.userRole != "SUPER") {
        this.isDisabled = true;
      }

      this.selectedOrganization = this.update_user.organization;
      this.organization_name = this.organizations.find(org => org.id === this.selectedOrganization)?.name;

    } catch (e) {
      showErrorMessage("Updating Failed", "Error parsing user data.");
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
  
<style>
@media (max-width: 450px) {
  #update-form {
    padding: 5px;
    font-size: 0.875rem;
  }

  #update-form input,
  #update-form select,
  #update-form textarea {
    height: 6vh;
    font-size: 0.875rem;
  }


  #update-form button,
  #update-form input[type="button"] {
    padding: 8px;
    font-size: 0.875rem;
  }
}
</style>
