<template>
  <div class="w-screen h-screen flex">
    <!-- Side bar -->
    <div :class="{ 'full-screen-sidebar': isSmallScreen, 'hidden': !showSide }"
      class="w-[400px] h-full bg-gray-200 text-white">
      <div class="h-[50px] bg-gray-900 flex justify-center items-center">
        <div class="px-[20px]">
          <h3 class="font-bold text-xl cursor-pointer" @click="isSmallScreen && toggleSideBar()">Stackdose Dashboard
          </h3>
        </div>
      </div>
      <div class="h-[calc(100vh-50px)] bg-gray-800 py-[20px] flex justify-center">
        <div class="flex flex-col justify-between h-full px-[20px] space-y-[10px]">
          <div class=" flex flex-col justify-between space-y-[10px]">
            <button v-if="!isUserEmpty" @click="handleLogout"
              class="bg-blue-400 text-white rounded-md hover:bg-blue-300 p-2">Logout</button>
            <button v-else @click="handleLogin">Login</button>
            <div>
              <template v-for="(menuContent, menuName) in menus">
                <!-- 主選單 -->
                <router-link v-if="menuContent.path" :to="menuContent.path"
                  class="inline-flex relative items-center py-[10px] px-[10px] w-full rounded-md border-gray-200 hover:bg-gray-200 hover:text-gray-800 transition duration-400 ease-in-out"
                  :key="menuName + '-routerlink'" @click="selectMenu(menuName)">
                  <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z">
                    </path>
                  </svg>
                  {{ menuName }}
                </router-link>
                <div v-else @click="toggleSubmenu(menuName)"
                  class="inline-flex relative items-center py-[10px] px-[10px] w-full font-medium rounded-md border-gray-200 hover:bg-gray-300 hover:text-gray-800 transition duration-400 ease-in-out"
                  :key="menuName + '-div'">
                  <svg aria-hidden="true" class="mr-2 w-[25px] h-[25px] fill-current" fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z">
                    </path>
                  </svg>
                  {{ menuName }}
                </div>
                <!-- 子選單 -->
                <ul v-if="isSubmenuOpen === menuName" class="mt-2 space-y-2 text-sm font-medium"
                  :key="menuName + '-submenu'">
                  <li v-for="(submenuLink, submenuName) in menuContent.submenu" :key="submenuName"
                    class="px-8 py-2 hover:text-black hover:bg-gray-200 transition duration-200 cursor-pointer">
                    <router-link :to="submenuLink" @click="handleSubmenuClick">
                      {{ submenuName }}
                    </router-link>
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-full bg-gray-400">
      <div v-if="showSide && isSmallScreen" class="absolute inset-0 bg-black bg-opacity-50"></div>
      <div class="h-[50px] bg-gray-100 flex items-center shadow-sm px-[20px] w-full py-[10px] z-10 border-b ">
        <!-- Hambuger menu -->
        <div class="cursor-pointer w-[30px]" @click="toggleSideBar">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class=" w-[25px] h-[25px]">
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </div>
        <!-- Search bar -->
        <div class="w-full flex">
          <div class="pl-2 flex-grow flex items-center">
            <div v-if="selectedContent && this.isLogin()" class="selected-content-text font-bold text-center">{{
              this.$store.state.activeMenu }}
            </div>
          </div>
          <div class="w-[200px] ">
            <div class="flex items-center justify-start space-x-4" @click="toggleDrop">
              <img class="w-10 h-10 rounded-full border-2 border-gray-50"
                src="https://yt3.googleusercontent.com/zAjAkgV22X6n4jcxVlDz5cMCYn7ZT236VHoZbNsOa7UhlCpeMWEkcFA2evCxyxd_wEsO3gESWg=s900-c-k-c0x00ffffff-no-rj"
                alt="">
              <div class="font-semibold dark:text-black text-left">
                <div>{{ user.organization || 'N/A' }} ,{{ user.username || 'N/A' }}</div>
                <!-- <div class="text-xs text-gray-500 dark:text-gray-400">{{ user.role || 'N/A' }}</div> -->
              </div>
            </div>
            <!-- Drop down -->
            <div v-show="showDropDown"
              class="absolute right-[10px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1 text-left" role="none">
                <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                  id="menu-item-0">Account settings</a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                  id="menu-item-1">Support</a>
                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                  id="menu-item-2">License</a>
                <form method="POST" action="#" role="none">
                  <button type="submit" class="text-gray-700 block w-full px-4 py-2 text-left text-sm" role="menuitem"
                    tabindex="-1" id="menu-item-3">Sign out</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-[calc(100vh-50px)] bg-gray-50 p-[20px]">
        <div class="content-container border border-gray-300 rounded-md p-[20px] h-full" :style="{
          display: 'flex',
          justifyContent: 'center',
          alignItems: isTablePage ? 'flex-start' : 'center'
        }">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { handleLogout } from '@/utils';

export default {
  data() {
    return {
      smallScreenWidth: 768,
      activeMenu: null,
      selectedContent: '',
      tablePages: ['UserList', 'ProductList', 'OperationList', 'MachineList', 'MaintenanceList', 'ProductionList'],
      menus: {
        'Profile': {
          path: '/profile',
          submenu: {}
        },
        'Devices': {
          path: '/products',
          submenu: {}
        },
        'Organizations': {
          path: '',
          submenu: {
            'Users': '/users',
            'Register': '/register',
          }
        },
        'Records': {
          path: '',
          submenu: {
            'Operation': '/operations',
            'Production': '/productions'
          }
        },
        'Maintenance': {
          path: '',
          submenu: {
            'Request Form': '/maintenance',
            'List': '/MaintenanceList'
          }
        },
        'Settings': {
          path: '/settings',
          submenu: {}
        },
        'Downloads': {
          path: '/downloads',
          submenu: {}
        }
      },
      showDropDown: false,
      showSide: true,
      isSubmenuOpen: false,
      isSmallScreen: false,
    }
  },
  methods: {
    handleSubmenuClick() {
      if (window.innerWidth < this.smallScreenWidth) {
        this.showSide = false;
      }
    },
    adjustSideBarForScreenSize() {
      this.isSmallScreen = window.innerWidth < this.smallScreenWidth;
      if (this.isSmallScreen) {
        this.showSide = false;
      }
    },
    isLogin() {
      return this.user && Object.keys(this.user).length > 0;
    },
    selectMenu(menuName) {
      this.$store.commit('setActiveMenu', menuName);
      this.activeMenu = menuName;
      this.selectedContent = menuName;
      if (this.isSmallScreen) {
        this.showSide = false;
      }
    },
    handleLogout() {
      handleLogout();
    },
    handleLogin() {
      this.$router.push({ name: 'Login' });
    },
    toggleSubmenu(menuName) {
      this.$store.commit('setActiveMenu', menuName);
      this.selectedContent = menuName;
      if (this.isSubmenuOpen === menuName) {
        this.isSubmenuOpen = null;
      } else {
        this.isSubmenuOpen = menuName;
      }
    },
    toggleSideBar() {
      this.showSide = !this.showSide
    },
    toggleDrop() {
      this.showDropDown = !this.showDropDown
    }
  },
  mounted() {
    this.adjustSideBarForScreenSize();
    window.addEventListener('resize', this.adjustSideBarForScreenSize);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isUserEmpty() {
      return Object.keys(this.user).length === 0;
    },
    isTablePage() {
      return this.tablePages.includes(this.$route.name);
    }
  }
}
</script>

<style>
.selected-content-text {
  font-size: 1.5rem;
  /* 2xl 大小 */
}

.user-info {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .full-screen-sidebar {
    position: absolute;
    width: 70%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.content-container {
  /* border: 1px solid #d1d5db; */
  border-radius: 0.375rem;
  padding: 20px;
}

@media (max-width: 450px) {
  .selected-content-text {
    font-size: 0.8rem;
  }

  .user-info {
    font-size: 0.55rem;
  }

  .outer-container {
    padding: 0;
  }

  .content-container {
    border: none;
    border-radius: 0;
    padding: 0;
  }
}
</style>