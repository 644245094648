// TableUtilitiesMixin.js
export default {
  data() {
    return {
      currentSortField: null,
      currentSortOrder: 'asc',
      showPopup: false,
      activeFilter: null,
    }
  },
  methods: {
    toggleSort(field) {
      if (this.currentSortField === field) {
        // Toggle the sort order if the field is already being sorted
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Set the current field and default the sort order to ascending
        this.currentSortField = field;
        this.currentSortOrder = 'asc';
      }

      this.sortItems();
      this.$store.commit('setCurrentPage', 1);  // 將當前頁碼設置為1

    },

    sortItems() {
      this.items.sort((a, b) => {
        let comparison = 0;

        if (a[this.currentSortField] > b[this.currentSortField]) {
          comparison = 1;
        } else if (a[this.currentSortField] < b[this.currentSortField]) {
          comparison = -1;
        }

        return this.currentSortOrder === 'asc' ? comparison : -comparison;
      });
    },
    // 獲取唯一值
    getUniqueValues(fieldKey) {
      return [...new Set(this.items.map(item => item[fieldKey] || ''))];
    },
    // 顯示篩選彈出窗口
    showFilter(filterType) {
      this.activeFilter = filterType;
      this.showPopup = true;
    }
  }
}
