import { showErrorMessage } from "@/utils"
export default {
  data() {
    return {
      searchQuery: "",
      currentPage: 1,
      totalPages: 0,
      items: [],
      originalItems: [],
    }
  },
  computed: {
    responsiveItemsPerPage() {
      return window.innerHeight <= 800 ? 8 : 15;
    },
    // 計算當前應該顯示的項目
    displayedItems() {
      if (!this.items || this.items.length === 0) {
        return [];
      }
      const startIndex = (this.vuexCurrentPage - 1) * this.responsiveItemsPerPage;
      const endIndex = startIndex + this.responsiveItemsPerPage;
      console.log(this.items);
      return this.items.slice(startIndex, endIndex);
    },
    vuexCurrentPage() {
      return this.$store.state.currentPage;
    },
    vuexTotalPages() {
      return this.$store.state.totalPages;
    }
  },
  methods: {
    handleChangePage(page) {
      console.log(page);
      this.$store.commit('setCurrentPage', page);
    },
    resetSearch() {
      this.items = [...this.originalItems];
      this.searchQuery = "";
      this.countPages();
      this.$store.commit('setCurrentPage', 1);
    },
    searchItems(attribute) {
      if (this.searchQuery == null || this.searchQuery.trim() === "" || attribute == undefined) {
        return;
      }

      const filteredItems = this.items.filter(item => {
        let attributeValue = item[attribute];
        if (typeof attributeValue === 'number') {
          attributeValue = attributeValue.toString();
        }

        return attributeValue.toLowerCase().includes(this.searchQuery.toLowerCase());
      });

      this.items = filteredItems;
      this.countPages();
      this.$store.commit('setCurrentPage', 1);
    },
    resetPage() {
      if (this.currentPage >= this.totalPages) {
        if (--this.currentPage < 1) {
          this.currentPage = 1;
        }
        this.$store.commit('setCurrentPage', this.currentPage);
      }
    },
    countPages() {
      this.totalPages = Math.ceil(this.items.length / this.responsiveItemsPerPage);
      this.$store.commit('setTotalPages', this.totalPages);
    },
    handleFilterChange(filterData) {
      console.log(filterData);
      const { key, value } = filterData;
      this.items = this.items.filter(item => item[key] === value);
      //重新計數頁數
      this.countPages();
      this.$store.commit('setCurrentPage', 1);
    },
    filterUsersByRole(users, currentUserRole) {
      if (currentUserRole === "SUPER") {
        return users.filter(user => ['SUPER', 'ADMIN', 'MG', 'OP'].includes(user.role));
      }
      else if (currentUserRole === 'ADMIN') {
        return users.filter(user => ['ADMIN', 'MG', 'OP'].includes(user.role));
      }

      return users.filter(user => ['MG', 'OP'].includes(user.role));
    },

    async fetchDatas(apiFunction, id) {
      try {
        this.$store.commit('setTotalPages', 0);
        this.$store.commit('setCurrentPage', 1);
        const response = id == "undefined" ? await apiFunction() : await apiFunction(id)
        if (response && response.data) {
          this.items = response.data;
          this.originalItems = [...this.items];
        }
      } catch (error) {
        showErrorMessage("Error fetching data:", error.message || "Unknown error");
      }
    },
  }
};
