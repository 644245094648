<!-- LoadingIndicator.vue -->
<template>
    <div class="loading-overlay" v-show="isLoading">
        {{ loadingText }}
    </div>
</template>
  
<script>
export default {
    props: {
        isLoading: Boolean,
        loadingText: {
            type: String,
            default: 'Loading...'
        }
    }
}
</script>
  
<style>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
</style>
  