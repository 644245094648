<template>
  <div id="search-div">
    <div class="flex flex-wrap justify-between items-center">
      <!-- Toggle Button -->
      <button @click="toggleOptions" class="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-400 sm:hidden">Toggle
        Options</button>
      <div class="flex items-center space-x-2" v-if="isOptionsVisible">
        <!-- Search Input and Buttons -->
        <div v-if="showSearch" class="flex items-center space-x-2">
          <label for="search-name" class="text-sm sm:text-base">Search by {{ fieldName }}:</label>
          <input type="text" id="search-name" name="search-name" v-model="localSearchQuery" placeholder="search..."
            class="border rounded p-1 text-sm sm:p-2 sm:text-base">
          <button @click="emitSearch"
            class="bg-blue-500 text-white px-2 py-1 text-sm sm:px-3 sm:py-2 sm:text-base  rounded hover:bg-blue-400">Search</button>
        </div>
        <button @click="emitReset"
          class="bg-gray-500 text-white px-2 py-1 text-sm sm:px-3 sm:py-2 sm:text-base  rounded hover:bg-gray-400">Reset</button>
        <button @click="toggleFilter"
          class="bg-red-500 text-white px-2 py-1 text-sm sm:px-3 sm:py-2 sm:text-base  rounded hover:bg-red-400">Filter</button>
      </div>
      <!-- Pagination -->
      <div class="pagination space-x-2" v-if="isOptionsVisible">
        <CustomPagination :currentPage="vuexCurrentPage" :totalPages="vuexTotalPages" @change-page="handleChangePage" />
      </div>
    </div>
  </div>
</template>


<script>
import CustomPagination from '@/components/CustomPagination.vue';

export default {

  data() {
    return {
      localSearchQuery: this.searchQuery,
      isOptionsVisible: window.innerWidth > 450
    };
  },
  components: {
    CustomPagination
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    searchQuery: {
      type: String,
      default: ""
    },
    vuexCurrentPage: {
      type: Number,
      default: 1
    },
    vuexTotalPages: {
      type: Number,
      default: 0
    },
    fieldName: String
  },
  methods: {
    toggleOptions() {
      this.isOptionsVisible = !this.isOptionsVisible;
    },
    toggleFilter() {
      this.$emit('toggle-popup');
    },
    updateSearchQuery(event) {
      this.$emit('update:searchQuery', event.target.value);
    },
    emitSearch() {
      if (!this.fieldName) {
        alert("Field name is required for search.");
        return;
      }
      this.$emit('search', this.fieldName);
    },
    emitReset() {
      this.$emit('reset');
      this.$emit('reset-field-name');
    },
    handleChangePage(page) {
      console.log(page);
      this.$emit('change-page', page);
    }
  },
  watch: {
    localSearchQuery(newVal) {
      this.$emit('update:searchQuery', newVal);
    },
    searchQuery(newVal) {
      this.localSearchQuery = newVal;
    }
  }
}
</script>

<style>
@media (max-width: 450px) {
  #search-div .flex.items-center {
    flex-wrap: wrap;
  }

  #search-div .flex.items-center>* {
    flex: 1 1 calc(50% - 10px);
    margin-bottom: 10px;
  }

  .toggle-button {
    display: block;
  }
}

.toggle-button {
  display: none;
}
</style>
