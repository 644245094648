<template>
  <div class="flex flex-col sm:flex-row items-center justify-center">
    <!-- 使用 sm: 前缀来定义小屏幕尺寸下的样式 -->
    <button :class="[disabled ? 'bg-gray-500' : 'bg-red-500', 'text-white py-1 px-2 rounded mb-2 sm:mb-0 sm:mr-4']"
      @click="onDelete" :disabled="disabled">
      Delete
    </button>
    <button class="bg-green-600 text-white py-1 px-2 rounded sm:mr-4" @click="onUpdate">
      Update
    </button>
  </div>
</template>

<script>
import { showWarningWithConfirmation } from "@/utils.js"

export default {
  props: {
    itemId: {
      type: [String, Number], // Depending on the type of your user ID
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onDelete() {
      const result = await showWarningWithConfirmation("You won't be able to revert this!", 'Yes, delete it!')
      if (result.isConfirmed) {
        this.$emit('delete', this.itemId);
      }
    },
    onUpdate() {
      this.$emit('update', this.itemId);
    },
  },
  mounted() {

  }

}
</script>
