<!-- Pagination.vue -->
<template>
  <div class="flex flex-wrap justify-center items-center space-x-2 sm:space-x-4">
    <button @click="prevPage" :disabled="currentPage === 1"
      class="bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-1 sm:py-2 px-2 sm:px-4 rounded my-2 text-xs sm:text-sm">
      Prev
    </button>

    <button @click="nextPage" :disabled="currentPage === totalPages"
      class="bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-1 sm:py-2 px-2 sm:px-4 rounded my-2 text-xs sm:text-sm">
      Next
    </button>
    <span class="text-xs sm:text-sm px-2 my-2">
      Page: {{ currentPage }} of {{ totalPages }}
    </span>
  </div>
</template>


<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit('change-page', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        console.log(this.currentPage, this.totalPages);
        this.$emit('change-page', this.currentPage + 1);
      }
    }
  }
}
</script>
