<template>
  <div class="w-full mx-auto mt-6 md:w-4/5">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <DateRangePicker :startDate="startDate" :endDate="endDate"
      @apply-date-filter="(dateRange) => handleDateFilter(dateRange, dateField)" @reset-date-filter="resetDateFilter" />
    <SearchPanel v-model:searchQuery="searchQuery" :vuexCurrentPage="vuexCurrentPage" :vuexTotalPages="vuexTotalPages"
      :fieldName="selectedFieldName" @search="searchItems" @reset="resetSearch" @toggle-popup="toggleFilterPanel"
      @change-page="handleChangePage" @reset-field-name="selectedFieldName = null" />
    <FilterPopup :isVisible="showPopup" :options="getUniqueValues(activeFilter)" :activeFilterKey="activeFilter"
      @filter-change="handleFilterChange" />
    <div class="table-responsive">
      <GenericTable :displayedItems="displayedItems" :fields="fields" :currentSortOrder="currentSortOrder"
        @show-filter="showFilter" @toggle-sort="toggleSort" @column-click="handleColumnClick">
        <template #table-body="slotProps">
          <tr v-for="item in slotProps.items" :key="item.id" class="border-b">
            <td class="hidden">{{ item.id }}</td>
            <td class="py-2 px-4" @click="showItemDetails(item)">{{ formatDate(item.date_joined) }}</td>
            <td class="py-2 px-4">{{ item.organization_name }}</td>
            <td class="py-2 px-4">{{ item.username }}</td>
            <td class="py-2 px-4">{{ item.role }}</td>
            <td class="py-2 px-4" v-if="canShowActionButtons()">
              <ActionButtons :itemId="item.id" @delete="handleDelete" @update="handleUpdate" />
            </td>
          </tr>
        </template>
      </GenericTable>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <p>Selected Item Details:</p>
        <div v-for="entry in getFilteredItem(selectedItem)" :key="entry.key">
          {{ wrappedFormatKey(entry.key) }}: {{ entry.value }}
        </div>
      </div>
    </div>
  </div>
</template>  
<script>
import FilterPopup from './base/FilterPopup'
import GenericTable from './base/GenericTable';
import tableListMixin from '@/mixins/tableListMixin';
import ActionButtons from "@/components/common/ActionButtons";
import tableUtilitiesMixin from '@/mixins/tableUtilitiesMixin';
import commonMethodsMixin from '@/mixins/commonMethodsMixin';
import { fetchUsers, deleteUser, fetchUsersByOrganization } from '@/api/users';
import SearchPanel from './base/SearchPanel';
import DateRangePicker from './base/DateRangePicker';
import { currentDate, formatKey, showErrorMessage } from "@/utils";

export default {
  mixins: [tableListMixin, tableUtilitiesMixin, commonMethodsMixin],
  data() {
    return {
      dateField: 'date_joined',
      showModal: false,
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'date_joined', label: 'Created' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'username', label: 'Username' },
        { key: 'role', label: 'Role' },
        { key: 'functions', label: 'Functions' },
      ],
    }
  },
  methods: {
    canShowActionButtons() {
      const allowedRoles = ['SUPER', 'ADMIN'];
      const userRole = this.$store.state.user.role;
      return allowedRoles.includes(userRole);
    },
    wrappedFormatKey(key) {
      return formatKey(key);
    },
    showItemDetails(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    getFilteredItem(item) {
      const keysToShow = ['date_joined', 'organization_name', 'username', 'email', 'role', 'training_cert'];
      return keysToShow.map(key => {
        return { key: key, value: item[key] };
      });
    },
    formatDate(date) {
      return currentDate(date);
    },
    async handleDelete(id) {
      const currentUserId = this.$store.state.user.id;
      //不能刪除自己
      if (id === currentUserId) {
        showErrorMessage("Error delete user:", "Cannot delete own account");
        return;
      }

      try {
        this.isLoading = true;
        await deleteUser(id);
        this.items = this.items.filter(item => item.id !== id);
        this.countPages();
        this.resetPage();
      } catch (error) {
        showErrorMessage('Error deleting user', error.message || 'Unknown error');
      } finally {
        this.isLoading = false;
      }
    },
    async handleUpdate(userId) {
      try {
        this.$router.push({
          name: 'UpdateUser',
          params: { userId: userId }
        });
      } catch (error) {
        console.error('Error navigating to update page:', error);
      }
    }
  },
  components: {
    GenericTable, FilterPopup, SearchPanel, ActionButtons, DateRangePicker
  },
  async mounted() {
    const user = this.$store.state.user;
    try {
      this.isLoading = true;
      if (user.role == "SUPER") {
        await this.fetchDatas(fetchUsers);
      } else {
        await this.fetchDatas(fetchUsersByOrganization, user.organization_id);
      }
      //篩選角色能查用的list
      this.items = this.filterUsersByRole(this.items, user.role);
      this.originalItems = [...this.items];
      this.countPages();
      this.$store.commit('setTotalPages', this.totalPages);
    } catch (error) {
      showErrorMessage('fetch UserList Failed', error.message || 'Unknown error');
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
  
<style>
@import '@/assets/css/table-styles.css';
</style>