<template>
  <div v-if="user" class="w-4/5 mx-auto mt-1">
    <div class="w-full flex flex-col items-center justify-center">
      <ul class="text-left list-disc pl-6 content-text">
        <li class="mb-2">Organization: {{ user.organization }}</li>
        <!-- <li class="mb-2">ID: {{ user.id }}</li> -->
        <li class="mb-2">Username: {{ user.username }}</li>
        <li class="mb-2">User Role: {{ user.role }}</li>
        <li class="mb-2">Email:{{ user.email }}</li>
        <li class="mb-2">Last Login: {{ formatDate(user.last_login) }}</li>
        <li class="mb-2">Training Cert: {{ user.training_cert ? 'Yes' : 'No' }}</li>
      </ul>
    </div>
  </div>
</template>
  
<script>

export default {
  components: {

  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  }, methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      return dateString.replace("T", " ");
    },
  }
};
</script>
  
<style>
.content-text {
  font-size: 1.5rem;
}

@media (max-width: 450px) {
  .content-text {
    font-size: 1rem;
  }
}
</style>