<template>
  <div class="w-full flex justify-center">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <!-- 其他组件内容 -->
    <form id="maintenance-form" action="" method="POST" @submit.prevent="submitMaintenance"
      class="flex flex-col px-10 py-6 items-center rounded-xl custom-form-width content-container">
      <h2 class="text-blue-700 text-3xl font-semibold my-1"> {{ maintenanceId ? 'Update Maintenance' : 'New Maintenance'
      }}
      </h2>
      <label for="reporter" class="text-sm">Reporter:</label>
      <input type="text" name="reporter" autocomplete="off" v-model="reporterName" required disabled
        class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4">
      <label for="organization" class="text-sm">Organization:</label>
      <input type="text" name="organization" autocomplete="off" v-model="organizationName" required disabled
        class="text-center h-8 w-full rounded-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-4">
      <div class="select-container">
        <label for="product" class="text-sm">Product:</label>
        <select name="product" v-model="selectedProduct" class="text-sm mx-1 py-2 px-4 mb-4">
          <option v-for="product in products" :key="product.id" :value="product.id">
            {{ product.modelname }}
          </option>
        </select>
        <label for="status" class="text-sm">Status:</label>
        <select name="status" v-model="selectedStatus" class="text-sm mx-1 py-2 px-4 mb-4"
          :disabled="user.role !== 'SUPER'">
          <option v-for="(value, key) in statusChoices" :key="key" :value="key">
            {{ value }}
          </option>
        </select>
      </div>
      <label for="description" class="text-sm">Description:</label>
      <textarea name="description" v-model="description" required
        class="w-full round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm p-2 mb-2"
        rows="4"></textarea>
      <div class="w-full" v-if="isSuperUser && maintenanceId">
        <!-- 只有超级用户可以看到的字段 -->
        <label for="maintenance_notes" class="text-sm">Maintenance Notes:</label>
        <textarea name="maintenance_notes" v-model="maintenanceNotes"
          class="w-full round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm p-2"
          rows="4"></textarea>
        <!-- 技術員選擇 -->
        <label for="technician" class="text-sm">Technician:</label>
        <select name="technician" v-model="selectedTechnician" class="text-sm mx-1 py-2 px-4 mb-4">
          <option v-for="technician in technicians" :key="technician.id" :value="technician.id">
            {{ technician.name }}
          </option>
        </select>
        <br>
        <label for="cost" class="text-sm">Cost:</label>
        <input type="number" name="cost" v-model="cost" step="0.01"
          class="text-center h-8 w-full round-md border border-slate-300 text-sm bg-transparent outline-blue-600 shadow-sm mb-2">
      </div>
      <div class="grid grid-cols-2 gap-4 justify-center w-full">
        <button type="submit"
          class="bg-blue-700 h-10 cursor-pointer text-white rounded-md hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm">Submit</button>
        <input type="button" v-if="userLevel == 1 && maintenanceId"
          class="bg-red-700 h-10 cursor-pointer text-white rounded-md hover:bg-green-600 hover:outline outline-2 outline-green-600 outline-offset-2 text-sm"
          value="Signature" @click="signature">
        <input type="button" v-else
          class="bg-red-700 h-10 cursor-pointer text-white rounded-md hover:bg-red-600 hover:outline outline-2 outline-red-600 outline-offset-2 text-sm"
          value="Reset" @click="resetForm">
      </div>
    </form>
  </div>
</template>  
<script>

import { showErrorMessage, showSuccessMessage, getCurrentDateTime, userLevel } from "@/utils";
import { fetchProductsByOrganization, createMaintenance, updateMaintenance, getMaintenanceById } from '@/api/products';
import { STATUS_CHOICES } from '@/constants';

export default {
  data() {
    return {
      userLevel: null,
      maintenanceId: null,
      selectedProduct: null,
      selectedStatus: null,
      selectedTechnician: null,
      description: '',
      maintenanceNotes: '',
      reporterName: '',
      organizationName: '',
      products: [],
      choiceStatus: [],
      technicians: [],
      statusChoices: STATUS_CHOICES,
      isLoading: false,
      cost: null,
      reporter: null,
      organization: null,
      isSuperUser: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    signature() {
      this.$router.push({ name: 'PageMaintenanceDetail' });
    },
    resetForm() {
      this.description = '';
    },
    buildRequestData() {
      return {
        reporter: this.reporter,
        organization: this.organization,
        product: this.selectedProduct,
        status: this.selectedStatus,
        description: this.description,
        maintenance_notes: this.maintenanceNotes,
        cost: this.cost,
        technician: this.selectedTechnician,
        maintenance_time: this.isSuperUser ? getCurrentDateTime() : null
      };
    },
    async handleMaintenanceSubmission(data) {
      this.isLoading = true;
      //判斷更新還是建立
      const response = this.maintenanceId ? await updateMaintenance(this.maintenanceId, data) : await createMaintenance(data);
      if (!response.data.success) {
        throw new Error('Failed to submit maintenance data');
      }
      return this.maintenanceId ? 'Update Maintenance' : 'New Maintenance';
    },
    async submitMaintenance() {
      const requestData = this.buildRequestData();
      try {
        const message = await this.handleMaintenanceSubmission(requestData);
        showSuccessMessage(`${message} Successful`, `You have been ${message} successfully!`);
        //this.$router.push({ name: "MaintenanceList" });
      } catch (error) {
        console.error('Error during maintenance submission:', error);
        showErrorMessage('Maintenance Submission Failed', error.message || 'Unknown error');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProducts() {
      this.isLoading = true;
      try {
        const response = await fetchProductsByOrganization(this.organization);
        this.products = response.data;
        if (this.products.length > 0 && !this.maintenanceId) {
          this.selectedProduct = this.products[0].id;
        }
      }
      catch (error) {
        console.error('Error fetching products:');
      } finally {
        this.isLoading = false;
      }
    },
    async loadMaintenanceData(maintenanceId) {
      this.isLoading = true;
      try {
        const response = await getMaintenanceById(maintenanceId);
        const data = response.data;
        console.log(data);
        if (data.success) {
          this.description = data.description;
          this.selectedStatus = data.status;
          this.selectedProduct = data.product;
          this.maintenanceNotes = data.maintenance_notes;
          this.cost = data.cost;
          this.selectedTechnician = data.technician;
          this.reporter = data.reporter;
          this.organization = data.organization;
          this.reporterName = data.reporter_name;
          this.organizationName = data.organization_name;
        } else {
          showErrorMessage('load Maintenance data Failed');
        }
      } catch (error) {
        showErrorMessage(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    this.maintenanceId = this.$route.params.maintenanceId || null;
    this.choiceStatus = STATUS_CHOICES;
    this.userLevel = userLevel(this.user.role);
    //預設選項
    this.selectedStatus = Object.keys(this.statusChoices)[0];
    this.technicians = [{
      id: this.user.id,
      name: this.user.username
    }];
    this.reporterName = this.user.username;
    this.organizationName = this.user.organization;
    this.reporter = this.user.id;
    this.organization = this.user.organization_id;
    this.isSuperUser = this.user.role === 'SUPER';

    //檢查是否是編輯模式
    if (this.maintenanceId) {
      await this.loadMaintenanceData(this.maintenanceId);
    }

    //讀取產品列表
    await this.fetchProducts();
  }
};

</script>
  
<style>
.content-container {
  overflow-y: auto;
  max-height: calc(100vh - 50px - 40px);
}

.custom-form-width {
  width: 80%;
  max-width: 600px;
  margin: auto;
}

textarea {
  text-align: center;
}

.select-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 450px) {

  .custom-form-width label,
  .custom-form-width input,
  .custom-form-width select {
    font-size: 0.875rem;
  }


  .custom-form-width {
    width: 100%;
  }


  .custom-form-width textarea {
    font-size: 0.875rem;
    height: auto;
    min-height: 100px;
  }


  .custom-form-width h2 {
    font-size: 1.25rem;
  }


  .select-container {
    flex-direction: row;
  }
}
</style>
