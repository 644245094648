import axios from "axios";

const debug = false;

const apiClient = axios.create({
    baseURL: debug ? "http://127.0.0.1:8000/api/" : "https://www.stackdose.org/api/",
    timeout: 30000, // 30 seconds
    headers: {
        "Content-Type": "application/json",
    },
});

export default apiClient;
