import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      itemId: null,
      user: JSON.parse(localStorage.getItem('user')) || {},
      currentPage: 1,
      totalPages: 0,
      activeMenu: null
    };
  },
  mutations: {
    setActiveMenu(state, menuName) {
      state.activeMenu = menuName;
    },
    setSelectedItemId(state, itemId) {
      state.itemId = itemId;
    },
    logout(state) {
      state.user = {};
      localStorage.removeItem('user');
    },
    updateUser(state, newUser) {
      state.user = newUser;
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      console.log("User has been set: ", state.user);
    },
    setCurrentPage(state, pageNumber) {
      console.log(pageNumber);
      state.currentPage = pageNumber;
    },
    setTotalPages(state, pageNumber) {
      state.totalPages = pageNumber;
    },
  },
});

export default store;