import apiClient from "./auth.js";
import { getHeaders } from "@/utils.js";

export const loginUser = (username, password) =>
  apiClient.post("/login/", { username, password }, { headers: getHeaders() });

export const logoutUser = () =>
  apiClient.post("/logout/");

export const fetchUsers = () =>
  apiClient.get("/users/");

export const fetchUsersByOrganization = (organizationId) =>
  apiClient.get(`users/organization/${organizationId}/`);

export const deleteUser = (userId) =>
  apiClient.delete(`/users/${userId}/`, { headers: getHeaders() });

export const getUser = (userId) =>
  apiClient.get(`/users/${userId}/`, { headers: getHeaders(false) });

export const updateUser = (user) =>
  apiClient.patch(`/users/${user.id}/`, user, { headers: getHeaders() });

export const registerUser = (user) =>
  apiClient.post(`/users/`, user, { headers: getHeaders() });

export const fetchOperations = () =>
  apiClient.get("/operation_logs/");

export const fetchModelsByProduct = (serialNumber) =>
  apiClient.get(`/models_by_product/${serialNumber}/`, { headers: getHeaders(false) });

export const getTrainingCertId = () =>
  apiClient.get("/generate-serial-number/");

export const fetchTrainingCertId = (trainingCertId) =>
  apiClient.get(`/training_certs/${trainingCertId}/`);

export const createTrainingCert = (data) =>
  apiClient.post(`/training_certs/`, data);

export const updateTrainingCert = (trainingCert) => {
  return apiClient.patch(`/training_certs/${trainingCert.id}/`, trainingCert, { headers: getHeaders() });
}

