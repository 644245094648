<template>
  <div class="w-5/5 mx-auto mt-12">
    <div class="flex flex-row">
      <div class="print-area flex-2">
        <h1 class="text-2xl font-semibold mb-4">Maintenance Report</h1>
        <ul class="text-left list-disc pl-6">
          <li v-for="(value, key) in filteredItem" :key="key" class="mb-2">
            {{ wrappedFormatKey(key) }}: {{ value }}
          </li>
        </ul>
        <div v-if="item && item.signature_data" class="signature-image">
          <img :src="item.signature_data" style="height:100px" alt="Signature">
        </div>
      </div>
      <div class="signature-area flex-1">
        <h2 class="text-xl font-semibold mb-4">電子簽名</h2>
        <canvas id="signature-canvas" width="300" height="100"></canvas>
        <p>{{ date }}</p>
      </div>
    </div>
    <div class="buttons-area mt-4">
      <button @click="clearSignature"><u>清除簽名</u></button>
      <button @click="updateSignature"
        class="bg-green-700 text-white px-4 py-2 rounded ml-2 hover:bg-green-400 btn-fixed-width">儲存</button>
      <button @click="printPage"
        class="bg-blue-500 text-white px-4 py-2 rounded ml-2 hover:bg-blue-400 btn-fixed-width">列印</button>
    </div>
  </div>
</template>

<script>
import { formatKey, currentDate } from "@/utils";
import { getMaintenanceById, updateMaintenance } from "@/api/products";


export default {
  data() {
    return {
      date: currentDate(),
      item: null,
    }
  },
  computed: {
    filteredItem() {
      const filtered = {};
      if (this.item) {
        for (const [key, value] of Object.entries(this.item)) {
          if (key !== 'signature_data') {
            filtered[key] = value;
          }
        }
      }
      return filtered;
    }
  },
  async mounted() {
    let itemId = this.$store.state.itemId;

    if (!itemId) {
      itemId = localStorage.getItem('itemId');
    }
    if (itemId) {
      await this.selectedItem(itemId);
    }

    console.log(this.item);
    this.initSignatureCanvas();
  },
  methods: {
    getSignatureData() {
      const canvas = document.getElementById('signature-canvas');
      return canvas.toDataURL();
    },
    updateSignature() {
      const signatureData = this.getSignatureData();
      if (signatureData && this.item) {
        this.item.signature_data = signatureData;
        updateMaintenance(this.item.id, this.item);
        console.log("update successful!");
      }
      console.log(signatureData);
    },
    async selectedItem(itemId) {
      const response = await getMaintenanceById(itemId);
      this.item = response.data;
      this.$store.commit('setSelectedItemId', itemId);
      localStorage.setItem('itemId', itemId);
    },
    wrappedFormatKey(key) {
      return formatKey(key);
    },
    printPage() {
      window.print();
    },
    initSignatureCanvas() {
      const canvas = document.getElementById('signature-canvas');
      const ctx = canvas.getContext('2d');
      canvas.addEventListener('mousedown', e => {
        ctx.beginPath();
        ctx.moveTo(e.offsetX, e.offsetY);
        const draw = e => {
          ctx.lineTo(e.offsetX, e.offsetY);
          ctx.stroke();
        };

        const stopDrawing = () => {
          canvas.removeEventListener('mousemove', draw);
          canvas.removeEventListener('mouseup', stopDrawing);
        };

        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseup', stopDrawing);
      });
    },
    clearSignature() {
      const canvas = document.getElementById('signature-canvas');
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }
}
</script>

<style>
.print-area {
  /* 占用 2/3 的空間 */
  flex: 2;
  padding: 10px;
  margin-right: 20px;
  /* 與簽名區域間隔 */
}

.print-area,
.signature-area {
  padding: 10px;
  max-width: 100%;
  /* 确保元素不超过容器宽度 */
}


.signature-area {
  flex: 1;
  margin-top: 10px;
  margin-left: 30px;
  padding: 10px;
}

.buttons-area {
  display: flex;
}

#signature-canvas {
  border: 1px solid #000 !important;
  /* background-color: #fff !important; */
}

@media print {
  body * {
    visibility: hidden;
  }

  .signature-image {
    visibility: visible;
  }

  .print-area,
  .print-area * {
    visibility: visible;
    /* 只顯示 print-area 區塊的內容 */
  }

  .signature-area,
  .signature-area * {
    visibility: hidden;
    /* 確保簽名區域在列印時也可見 */
  }

  .signature-area {
    position: static;
    /* 或根據需要調整位置 */
  }

  .print-area {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media (max-width: 768px) {
  .print-area {
    overflow-x: auto;
  }

  .signature-area {
    overflow-y: auto;
  }
}
</style>
