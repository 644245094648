<template>
  <div class="w-full mx-auto mt-6 md:w-4/5">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <DateRangePicker :startDate="startDate" :endDate="endDate"
      @apply-date-filter="(dateRange) => handleDateFilter(dateRange, dateField)" @reset-date-filter="resetDateFilter" />
    <SearchPanel v-model:searchQuery="searchQuery" :vuexCurrentPage="vuexCurrentPage" :vuexTotalPages="vuexTotalPages"
      :fieldName="selectedFieldName" @search="searchItems" @reset="resetSearch" @toggle-popup="toggleFilterPanel" />
    <FilterPopup :isVisible="showPopup" :options="getUniqueValues(activeFilter)" :activeFilterKey="activeFilter"
      @filter-change="handleFilterChange" />
    <div class="table-responsive">
      <GenericTable :displayedItems="displayedItems" :fields="dynamicFields" :currentSortOrder="currentSortOrder"
        @show-filter="showFilter" @toggle-sort="toggleSort" @column-click="handleColumnClick">
        <!-- <template #extra-headers>
          <th class="py-2 px-4">Functions</th>
        </template> -->
        <template #table-body="slotProps">
          <template v-if="!slotProps.items || slotProps.items.length === 0">
            <tr>
              <td class="py-2 px-4 td-text">Empty data</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="item in slotProps.items" :key="item.id" class="border-b">
              <td class="py-2 px-4 td-text">{{ item.manufacture_date }}</td>
              <td class="py-2 px-4 td-text">{{ item.type }}</td>
              <td class="py-2 px-4 td-text">{{ item.model_name }}</td>
              <td class="py-2 px-4 td-text">{{ item.used_time }}/{{ item.total_available_time }}</td>
              <td class="py-2 px-4 td-text" v-if="canShowCode()">{{ item.code }}</td>
              <!-- <td class="py-2 px-4 td-text" v-if="canShowReplaceButton()">
                <button class="bg-green-600 text-white py-1 px-2 rounded sm:mr-4" @click="handleReplace(item)">
                  Replace
                </button>
              </td> -->
            </tr>
          </template>
        </template>
      </GenericTable>
    </div>
  </div>
  <replace-component :is-visible="showReplaceModal" :item="selectedItem" :options="replaceOptions"
    @replace="handleReplace" @remove="handleRemove" @update:isVisible="showReplaceModal = $event">
  </replace-component>
</template>
<script>
import FilterPopup from './base/FilterPopup'
import GenericTable from './base/GenericTable';
import tableListMixin from '@/mixins/tableListMixin';
import tableUtilitiesMixin from '@/mixins/tableUtilitiesMixin';
import { fetchModelsByProduct } from '@/api/products';
import SearchPanel from './base/SearchPanel';
import { showErrorMessage } from "@/utils";
import ReplaceComponent from '@/components/ReplaceComponent';
import DateRangePicker from './base/DateRangePicker';
import commonMethodsMixin from '@/mixins/commonMethodsMixin';

export default {
  mixins: [tableListMixin, tableUtilitiesMixin, commonMethodsMixin],
  data() {
    return {
      dateField: 'manufacture_date',
      isLoading: false,
      showReplaceModal: false,
      selectedItem: null,
      userRole: null,
      replaceOptions: [],
      machineData: {
        ffu: [],
        gule: [],
        head: [],
        powder: []
      },
      fields: [
        { key: 'manufacture_date', label: 'Created' },
        { key: 'type', label: 'Type' },
        { key: 'code', label: 'Code' },
        { key: 'used_time', label: 'UsedTime' },
        { key: 'serial_number', label: 'SerialNumber' },
      ],
    }
  },
  computed: {
    dynamicFields() {
      const baseFields = [
        { key: 'manufacture_date', label: 'Created' },
        { key: 'type', label: 'Type' },
        { key: 'model_name', label: 'ModelName' },
        { key: 'used_time', label: 'UsedTime' },
        // { key: 'code', label: 'Code' },
        // 其他字段...
      ];

      if (this.canShowCode()) {
        baseFields.push({ key: 'code', label: 'Code' });
      }

      return baseFields;
    }
  },
  methods: {
    canShowCode() {
      return this.userRole == "SUPER";
    },
    canShowReplaceButton() {
      const allowedRoles = ['SUPER'];
      return allowedRoles.includes(this.userRole);
    },
    handleRemove() {
      console.log("remove", this.selectedItem);
      this.selectedItem
    },
    handleReplace(item) {
      this.replaceOptions = this.loadReplaceOptions(item);
      this.selectedItem = item;
      this.showReplaceModal = true;
    },
    loadReplaceOptions(item) {
      console.log(item);
      return [
        { value: 'option1', text: 'test 1' },
        { value: 'option2', text: 'test 2' },
      ];
    },
    onConfirmReplacement(selectedOption) {
      console.log(selectedOption);
      this.showReplaceModal = false;
    },
    flattenMachineData(data) {
      const flattened = [];
      for (const [type, items] of Object.entries(data)) {
        items.forEach(item => {
          flattened.push({ type, ...item });
        });
      }
      return flattened;
    },
    async fetchDatas(apiFunction, params) {
      try {
        this.isLoading = true;
        this.$store.commit('setTotalPages', 0);
        this.$store.commit('setCurrentPage', 1);
        const response = await apiFunction(params);
        if (response.data) {
          const flattenedData = this.flattenMachineData(response.data);
          this.items = flattenedData;
          this.originalItems = [...flattenedData];
        }
      } catch (error) {
        showErrorMessage("Error fetching data:", error.message || "Unknown error");
      } finally {
        this.isLoading = false;
      }
    }
  },
  components: {
    GenericTable, FilterPopup, SearchPanel, ReplaceComponent, DateRangePicker
  },
  async mounted() {
    try {
      this.isLoading = true;
      const serialNumber = this.$route.params.serialNumber;
      this.userRole = this.$store.state.user.role;
      await this.fetchDatas(fetchModelsByProduct, serialNumber);
      this.countPages();
      this.$store.commit('setTotalPages', this.totalPages);
    } catch (error) {
      showErrorMessage('fetch Models Failed', error.message || 'Unknown error');
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
<style>
@import '@/assets/css/table-styles.css';
</style>