<!-- FilterPopup.vue -->
<template>
  <div v-if="isVisible" class="filter-popup bg-white border rounded shadow-lg p-3">
    <label class="block mb-2 font-semibold text-gray-600">Filter by:</label>
    <select v-model="selectedValue" @change="onFilterChange"
      class="block w-full border rounded-lg shadow-sm bg-white hover:border-gray-300 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
      <option v-for="option in options" :key="option">{{ option }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    activeFilterKey: {
      type: [String, null],
      required: true
    }
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  methods: {
    onFilterChange() {
      this.$emit('filter-change', { key: this.activeFilterKey, value: this.selectedValue });
    },
  },
}
</script>
