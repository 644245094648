import { showErrorMessage } from "@/utils";
import tableListMixin from '@/mixins/tableListMixin';

export default {
  mixins: [tableListMixin],
  data() {
    return {
      selectedFieldName: "",
      startDate: null,
      endDate: null,
      isLoading: false
    }
  },
  methods: {
    async fetchPageData(apiFunction, apiName) {
      this.isLoading = true;
      try {
        await this.fetchDatas(apiFunction);
        this.countPages();
        this.$store.commit('setTotalPages', this.totalPages);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        showErrorMessage(`Error fetching ${apiName}`);
      } finally {
        this.isLoading = false;
      }
    },
    handleColumnClick(fieldName) {
      this.selectedFieldName = fieldName;
    },
    toggleFilterPanel() {
      this.showPopup = !this.showPopup;
    },
    resetDateFilter() {
      this.items = [...this.originalItems];
      this.startDate = null;
      this.endDate = null;
    },

    handleDateFilter(dateRange, dateField) {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      console.log(this.startDate, this.endDate);
      if (!this.startDate || !this.endDate) {
        alert("請選擇開始跟結束日期");
        return;
      }

      const start = new Date(this.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(this.endDate);
      end.setHours(23, 59, 59, 999);

      this.items = this.originalItems.filter(item => {
        const reportTime = new Date(item[dateField]);
        return reportTime >= start && reportTime <= end;
      });
    },
  }
}