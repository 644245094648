<template>
  <div>
    this is Downloads Page
  </div>
</template>
  
  
<script>


export default {
  components: {

  },
};
</script>
  
<style></style>
