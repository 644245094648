<template>
  <div class="w-full mx-auto mt-6 md:w-4/5">
    <loading-indicator :isLoading="isLoading" :loadingText="loadingText"></loading-indicator>
    <DateRangePicker :startDate="startDate" :endDate="endDate"
      @apply-date-filter="(dateRange) => handleDateFilter(dateRange, dateField)" @reset-date-filter="resetDateFilter" />
    <SearchPanel v-model:searchQuery="searchQuery" :vuexCurrentPage="vuexCurrentPage" :vuexTotalPages="vuexTotalPages"
      :fieldName="selectedFieldName" @search="searchItems" @reset="resetSearch" @toggle-popup="toggleFilterPanel"
      @change-page="handleChangePage" />
    <FilterPopup :isVisible="showPopup" :options="getUniqueValues(activeFilter)" :activeFilterKey="activeFilter"
      @filter-change="handleFilterChange" />
    <div class="table-responsive">
      <GenericTable :displayedItems="displayedItems" :fields="fields" :currentSortOrder="currentSortOrder"
        @show-filter="showFilter" @toggle-sort="toggleSort" @column-click="handleColumnClick">
        <template #table-body="slotProps">
          <tr v-for="item in slotProps.items" :key="item.id" class="border-b">
            <td v-for="field in fields" :class="field.class" :key="field.key">
              {{ item[field.key] }}
            </td>
          </tr>
        </template>
      </GenericTable>
    </div>
    <div style="text-align: right; padding-top: 6px;">
      <button @click="exportAsPDF"><u>輸出為pdf</u></button>
    </div>
  </div>
</template>
<script>
import FilterPopup from './base/FilterPopup'
import GenericTable from './base/GenericTable';
import tableListMixin from '@/mixins/tableListMixin';
import tableUtilitiesMixin from '@/mixins/tableUtilitiesMixin';
import { fetchOperations, fetchOperationsByOrganization } from '@/api/products';
import SearchPanel from './base/SearchPanel';
import { exportAsPDF, showErrorMessage } from "@/utils";
import DateRangePicker from './base/DateRangePicker';
import commonMethodsMixin from '@/mixins/commonMethodsMixin';

export default {
  mixins: [tableListMixin, tableUtilitiesMixin, commonMethodsMixin],
  components: {
    GenericTable, FilterPopup, DateRangePicker, SearchPanel
  },
  data() {
    return {
      isLoading: false,
      loadingText: 'Loading...',
      dateField: 'created_date',
      fields: [
        { key: 'id', label: 'Id', class: 'hidden' },
        { key: 'created_date', label: 'Created', class: 'py-2 px-4 smaller-text' },
        { key: 'log_name', label: 'LogoType', class: 'py-2 px-4 td-text' },
        { key: 'level', label: 'Level', class: 'py-2 px-4 td-text' },
        { key: 'message', label: 'Message', class: 'py-2 px-4 td-text' },
        { key: 'organization_name', label: 'Organization', class: 'py-2 px-4 td-text' },
        { key: 'username', label: 'User', class: 'py-2 px-4 td-text' },
        { key: 'modelname', label: 'Device', class: 'py-2 px-4 td-text' },
      ],
    }
  },
  methods: {
    async exportAsPDF() {
      this.isLoading = true;
      this.loadingText = "Processing...";
      await exportAsPDF(this.items);
      this.$nextTick(() => {
        this.isLoading = false;
      });
    },
  },
  async mounted() {
    const user = this.$store.state.user;
    try {
      this.isLoading = true;
      if (user.role == "SUPER") {
        await this.fetchDatas(fetchOperations);
      } else {
        await this.fetchDatas(fetchOperationsByOrganization, user.organization_id);
      }
      this.countPages();
      this.$store.commit('setTotalPages', this.totalPages);
    } catch (error) {
      showErrorMessage('fetch Operations Failed', error.message || 'Unknown error');
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
