<template>
  <div class="w-full 4/5 mx-auto mt-6 md:w-4/5">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <DateRangePicker :startDate="startDate" :endDate="endDate"
      @apply-date-filter="(dateRange) => handleDateFilter(dateRange, dateField)" @reset-date-filter="resetDateFilter" />
    <SearchPanel v-model:searchQuery="searchQuery" :vuexCurrentPage="vuexCurrentPage" :vuexTotalPages="vuexTotalPages"
      :fieldName="selectedFieldName" @search="searchItems" @reset="resetSearch" @toggle-popup="toggleFilterPanel"
      @reset-field-name="selectedFieldName = null" />
    <FilterPopup :isVisible="showPopup" :options="getUniqueValues(activeFilter)" :activeFilterKey="activeFilter"
      @filter-change="handleFilterChange" />
    <div class="table-responsive">
      <GenericTable :displayedItems="displayedItems" :fields="fields" :currentSortOrder="currentSortOrder"
        @show-filter="showFilter" @toggle-sort="toggleSort" @column-click="handleColumnClick">
        <template #table-body="slotProps">
          <tr v-for="item in slotProps.items" :key="item.id" class="border-b hover-highlight"
            @click="goToMachineList(item.serial_number)" clickable>
            <td class="hidden">{{ item.id }}</td>
            <td class="py-2 px-4 smaller-text">
              {{ item.update_time ? item.update_time : '' }}
            </td>
            <td class="py-2 px-4 td-text">{{ item.organization_name }}</td>
            <td class="py-2 px-4 td-text">{{ item.modelname }}</td>
            <td class="py-2 px-4 td-text">{{ item.code }}</td>
            <td class="py-2 px-4 td-text"
              :class="{ 'status-offline': item.status === 'Offline', 'status-active': item.status !== 'offline' }">
              {{ item.status }}
            </td>

            <td class="py-2 px-4 td-text">{{ item.tablet_name }}</td>
            <td class="py-2 px-4 td-text">{{ item.drug_authorization_quantity }}</td>
            <td class="py-2 px-4 td-text">{{ item.completed_production_quantity }}</td>
          </tr>
        </template>
      </GenericTable>
    </div>

  </div>
</template>
<script>
import FilterPopup from './base/FilterPopup'
import GenericTable from './base/GenericTable';
import tableListMixin from '@/mixins/tableListMixin';
import tableUtilitiesMixin from '@/mixins/tableUtilitiesMixin';
import commonMethodsMixin from '@/mixins/commonMethodsMixin';
import { fetchProducts, fetchProductsByOrganization } from '@/api/products';
import SearchPanel from './base/SearchPanel';
import DateRangePicker from './base/DateRangePicker';
import { showErrorMessage } from "@/utils";

export default {
  mixins: [tableListMixin, tableUtilitiesMixin, commonMethodsMixin],
  data() {
    return {
      isLoading: false,
      dateField: 'update_time',
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'update_time', label: 'UpdateTime' },
        { key: 'organization_name', label: 'Organization' },
        { key: 'modelname', label: 'Name' },
        { key: 'code', label: 'Code' },
        { key: 'status', label: 'Status' },
        { key: 'tablet_name', label: 'Tablet' },
        { key: 'drug_authorization_quantity', label: 'Authorization' },
        { key: 'completed_production_quantity', label: 'Completed' },

      ],
    }
  },
  methods: {
    goToMachineList(serialNumber) {
      this.$router.push({ name: 'MachineList', params: { serialNumber: serialNumber } });
    }
  },
  components: {
    GenericTable, FilterPopup, SearchPanel, DateRangePicker
  },
  async mounted() {
    const user = this.$store.state.user;
    try {
      this.isLoading = true;
      if (user.role == "SUPER") {
        await this.fetchDatas(fetchProducts);
      } else {
        await this.fetchDatas(fetchProductsByOrganization, user.organization_id);
      }
      this.countPages();
      this.$store.commit('setTotalPages', this.totalPages);
    } catch (error) {
      showErrorMessage('fetch Equipments Failed', error.message || 'Unknown error');
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/table-styles.css';

.hover-highlight:hover {
  background-color: #bababa;
  cursor: pointer;
}

.status-active {
  color: #32CD32;
  /* 艷綠色 */
}

.status-offline {
  color: #8B0000;
  /* 暗紅色 */
}
</style>