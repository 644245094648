<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
    <div class="bg-white rounded-lg p-4 max-w-sm w-full">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="replace-option">
          Select Component to Replace
        </label>
        <select v-model="selectedOption" id="replace-option" class="shadow border rounded w-full py-2 px-3 text-gray-700">
          <option v-for="option in options" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>
      <div class="flex justify-end">
        <button @click="confirmReplacement" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Replace
        </button>
        <button @click="confirmRemove"
          class="ml-2 bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded">
          Remove
        </button>
        <button @click="close" class="ml-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    item: Object,
  },
  data() {
    return {
      selectedOption: null
    };
  },
  methods: {
    confirmRemove() {
      this.$emit('remove');
      this.close();
    },
    confirmReplacement() {
      this.$emit('replace', this.selectedOption);
      this.close();
    },
    close() {
      this.$emit('update:isVisible', false);
    }
  }
};
</script>
