<template>
  <div>
    <router-view />
    <back-button></back-button>
  </div>
</template>

<script>
import BackButton from './pages/base/BackButton.vue';
import apiClient from "./api/auth.js";



export default {
  name: 'App',
  components: {
    BackButton
  },
  data() {
    return {
      intervalId: null, // 用來儲存定時器的 ID
    };
  },
  methods: {
    async fetchUpdateDevicesOffline() {
      try {
        // 呼叫後端 API
        const response = await apiClient.get("products/update-devices-offline/");
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("Failed to update devices offline:", error.message);
      }
    },
    startInterval() {
      // 每10分鐘執行一次（600,000毫秒）
      this.intervalId = setInterval(() => {
        this.fetchUpdateDevicesOffline();
      }, 600000);

      // 初始化執行一次
      this.fetchUpdateDevicesOffline();
    },
    clearInterval() {
      // 清除定時器
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
  async mounted() {
    await this.fetchUpdateDevicesOffline();
    this.startInterval();
  },
  beforeUnmount() {
    this.clearInterval();
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn-fixed-width {
  width: 80px;
}

.filter-popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

th.filter:hover {
  background-color: #e0e0e0;
}

th>span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hidden-column {
  display: none;
}
</style>
