<template>
  <div class="date-range-picker">
    <div class="flex flex-wrap justify-between items-center space-y-2 sm:space-y-0">
      <div class="flex space-x-2">
        <!-- Start Date -->
        <div class="flex flex-col sm:flex-row items-center">
          <label for="start-date" class="text-sm sm:text-base">Start Date:</label>
          <input type="date" id="start-date" v-model="localStartDate"
            class="border rounded p-1 text-sm sm:p-2 sm:text-base">
        </div>
        <!-- End Date -->
        <div class="flex flex-col sm:flex-row items-center">
          <label for="end-date" class="text-sm sm:text-base">End Date:</label>
          <input type="date" id="end-date" v-model="localEndDate" class="border rounded p-1 text-sm sm:p-2 sm:text-base">
        </div>
      </div>
      <!-- Buttons -->
      <div class="flex space-x-2">
        <button @click="applyDateFilter"
          class="bg-green-500 text-white px-2 py-1 text-sm sm:px-4 sm:py-2 sm:text-base rounded hover:bg-green-400">Apply</button>
        <button @click="resetDateFilter"
          class="bg-gray-500 text-white px-2 py-1 text-sm sm:px-4 sm:py-2 sm:text-base rounded hover:bg-gray-400">Reset</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localStartDate: this.startDate,
      localEndDate: this.endDate,
    };
  },
  props: {
    startDate: String,
    endDate: String,
  },
  methods: {
    applyDateFilter() {
      this.$emit('apply-date-filter', { startDate: this.localStartDate, endDate: this.localEndDate });
    },
    resetDateFilter() {
      this.localStartDate = '';
      this.localEndDate = '';
      this.$emit('reset-date-filter');
    }
  },
  watch: {
    startDate(newVal) {
      this.localStartDate = newVal;
    },
    endDate(newVal) {
      this.localEndDate = newVal;
    }
  }
}
</script>

<style scoped>
/* Your styles here */
</style>
