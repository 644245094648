import apiClient from "./auth.js";
import { getHeaders } from "@/utils.js";

export const fetchProducts = () =>
  apiClient.get("/products/");

export const fetchOperationsByOrganization = (organization_id) =>
  apiClient.get(`/operation_logs/organization/${organization_id}/`);

export const fetchOperations = () =>
  apiClient.get("/operation_logs/");

export const fetchProductions = () =>
  apiClient.get("/production_logs/");

export const fetchProductionsByOrganization = (organization_id) =>
  apiClient.get(`/production_logs/organization/${organization_id}/`);

export const fetchMaintenancesByUser = (user_id) =>
  apiClient.get(`/maintenance/user/${user_id}/`);

export const fetchAllMaintenances = () =>
  apiClient.get('/maintenance/');

export const fetchMaintenancesByOrganization = (organization_id) =>
  apiClient.get(`/maintenance/organization/${organization_id}/`);

export const fetchModelsByProduct = (serialNumber) =>
  apiClient.get(`/models_by_product/${serialNumber}/`);

export const fetchProductsByOrganization = (organizationId) =>
  apiClient.get(`/products/organization/${organizationId}/`);

export const getMaintenanceById = (maintenanceId) =>
  apiClient.get(`/maintenance/${maintenanceId}/`);

export const createMaintenance = (requestData) =>
  apiClient.post(`/maintenance/`, requestData, { headers: getHeaders() });

export const updateMaintenance = (maintenanceId, requestData) =>
  apiClient.patch(`/maintenance/${maintenanceId}/`, requestData, { headers: getHeaders() });

export const deleteMaintenance = (maintenanceId) =>
  apiClient.delete(`/maintenance/${maintenanceId}/`, { headers: getHeaders() });