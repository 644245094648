<template>
  <table class="w-full border-collapse border border-gray-300" ref="tableToExport">
    <thead>
      <tr class="bg-gray-100 text-center">
        <th v-for="field in fields" :key="field.key" @click="$emit('show-filter', field.key)"
          :class="{ 'hidden-column': field.key === 'id' }" class="py-2 px-4 filter">
          {{ field.label }}
          <span @click.stop="$emit('toggle-sort', field.key)" class="ml-2 cursor-pointer">
            {{ currentSortOrder === 'asc' ? '↑' : '↓' }}
          </span>
          <span class="ml-2 cursor-pointer" @click="emitColumnClick(field.key)">
            🔍
          </span>
        </th>
        <slot name="extra-headers"></slot>
      </tr>
    </thead>
    <tbody>
      <slot name="table-body" :items="displayedItems"></slot>
    </tbody>
  </table>
</template>

<script>

export default {
  props: {
    displayedItems: Array,
    fields: Array,
    currentSortOrder: String,
  },
  methods: {
    emitColumnClick(fieldName) {
      this.$emit('column-click', fieldName);
    },
  },
}
</script>
