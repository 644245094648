<template>
  <div class="w-full mx-auto mt-6 md:w-4/5">
    <loading-indicator :isLoading="isLoading"></loading-indicator>
    <DateRangePicker :startDate="startDate" :endDate="endDate" @apply-date-filter="handleDateFilter"
      @reset-date-filter="resetDateFilter" />
    <SearchPanel class="mt-2" :showSearch="true" :fieldName="selectedFieldName" v-model:searchQuery="searchQuery"
      :vuexCurrentPage="vuexCurrentPage" :vuexTotalPages="vuexTotalPages" @search="searchItems" @reset="resetSearch"
      @toggle-popup="toggleFilterPanel" @change-page="handleChangePage" />
    <FilterPopup :isVisible="showPopup" :options="getUniqueValues(activeFilter)" :activeFilterKey="activeFilter"
      @filter-change="handleFilterChange" />
    <div class="table-responsive">
      <GenericTable :displayedItems="displayedItems" :fields="fields" :currentSortOrder="currentSortOrder"
        @show-filter="showFilter" @toggle-sort="toggleSort" @column-click="handleColumnClick">
        <template #table-body="slotProps">
          <tr v-for="item in slotProps.items" :key="item.id" class="border-b">
            <td class="py-2 px-4" @click="showItemDetails(item)">{{ item.report_time }}</td>
            <td v-if="showOrganization" class="py-2 px-4">{{ item.organization_name }}</td>
            <td class="py-2 px-4">{{ item.reporter_name }}</td>
            <td class="py-2 px-4">{{ item.product_model_name }}</td>
            <td class="py-2 px-4">{{ item.status }}</td>
            <td class="py-2 px-4">{{ item.technician_name }}</td>
            <td class="py-2 px-4" v-if="canShowActionButtons()">
              <ActionButtons :itemId="item.id" @delete="handleDelete" @update="handleUpdate" />
            </td>
          </tr>
        </template>
      </GenericTable>
    </div>
    <div style="text-align: right; padding-top: 6px;">
      <button @click="wrappedExportAsPDF"><u>輸出為pdf</u></button>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <p>Selected Item Details:</p>
        <div v-for="entry in getFilteredItem(selectedItem)" :key="entry.key">
          <p :class="{ 'preserve-whitespace': entry.key === 'maintenance_notes' }">
            {{ wrappedFormatKey(entry.key) }}: {{ entry.value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>  
<script>
import FilterPopup from './base/FilterPopup'
import SearchPanel from './base/SearchPanel';
import DateRangePicker from './base/DateRangePicker';
import GenericTable from './base/GenericTable';
import ActionButtons from "@/components/common/ActionButtons";
import tableListMixin from '@/mixins/tableListMixin';
import tableUtilitiesMixin from '@/mixins/tableUtilitiesMixin';
import { showErrorMessage, formatKey, exportAsPDF } from "@/utils"
import { fetchAllMaintenances, fetchMaintenancesByOrganization, deleteMaintenance } from '@/api/products';
import commonMethodsMixin from '@/mixins/commonMethodsMixin';

export default {
  mixins: [tableListMixin, tableUtilitiesMixin, commonMethodsMixin],
  data() {
    return {
      showOrganization: false,
      isLoading: false,
      showModal: false,
      dateField: 'report_time',
      fields: [
        { key: 'report_time', label: 'Report Time' },
        { key: 'reporter_name', label: 'Reporter' },
        { key: 'product_model_name', label: 'Product' },
        { key: 'status', label: 'Status' },
        { key: 'technician_name', label: 'Technician' },
        { key: 'functions', label: 'Functions' },
      ],
    }
  },
  methods: {
    async wrappedExportAsPDF() {
      this.isLoading = true;
      this.loadingText = "Processing...";
      const exportItems = [];
      this.items.forEach((item) => {
        exportItems.push(this.getReduceItem(item))
      });

      await exportAsPDF(exportItems, `Maintenance List`);
      this.$nextTick(() => {
        this.isLoading = false;
      });
    },
    canShowActionButtons() {
      const allowedRoles = ['SUPER', 'ADMIN'];
      const userRole = this.$store.state.user.role;
      return allowedRoles.includes(userRole);
    },
    getReduceItem(item) {
      const keysToShow = ['report_time', 'organization_name', 'product_model_name', 'reporter_name', 'status', 'maintenance_time', 'maintenance_notes', 'cost', 'signature_data'];
      return keysToShow.reduce((acc, key) => {
        acc[key] = key == 'signature_data' ? (item[key] == null ? "No" : "Yes") : item[key];
        return acc;
      }, {});
    },
    //popup
    getFilteredItem(item) {
      const keysToShow = ['id', 'report_time', 'organization_name', 'product_model_name', 'reporter_name', 'status', 'maintenance_time', 'maintenance_notes', 'cost', 'signature_data'];
      return keysToShow.map(key => {
        return { key: key, value: key == 'signature_data' ? item[key] == null ? "No" : "Yes" : item[key] };
      });
    },
    wrappedFormatKey(key) {
      return formatKey(key);
    },
    showItemDetails(item) {
      this.selectedItem = item;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async handleDelete(id) {
      try {
        await deleteMaintenance(id);
        this.items = this.items.filter(item => item.id !== id);
        this.countPages();
        this.resetPage();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
    async handleUpdate(maintenanceId) {
      try {
        this.$store.commit('setSelectedItemId', maintenanceId);
        this.$router.push({
          name: 'UpdateMaintenance',
          params: { maintenanceId: maintenanceId }
        });
      } catch (error) {
        console.error('Error navigating to Maintenance update page:', error);
      }
    }
  },
  components: {
    GenericTable, FilterPopup, ActionButtons, DateRangePicker, SearchPanel
  },
  async mounted() {
    const user = this.$store.state.user;
    try {
      this.isLoading = true;
      if (user.role == "SUPER") {
        await this.fetchDatas(fetchAllMaintenances);
        this.fields.splice(1, 0, { key: 'organization_name', label: 'Organization' });
        this.showOrganization = true;
      } else {
        await this.fetchDatas(fetchMaintenancesByOrganization, user.organization_id);
      }
      this.countPages();
      this.$store.commit('setTotalPages', this.totalPages);
    } catch (error) {
      showErrorMessage('fetch Operations Failed', error.message || 'Unknown error');
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
  
<style>
@import '@/assets/css/table-styles.css';
</style>