<template>
  <div v-if="isLoggedIn" class="back-button" @click="goBack">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="arrow-icon">
      <path d="M19 12H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 19L5 12L12 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.user && Object.keys(this.$store.state.user).length > 0;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
.back-button {
  position: fixed;
  right: 30px;
  /* 距離右邊界 30px */
  bottom: 30px;
  /* 距離底部邊界 30px */
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: black;
  /* 按鈕背景為黑色 */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.arrow-icon {
  fill: none;
  /* 移除填充 */
  stroke: white;
  /* 箭頭為白色 */
  width: 24px;
  height: 24px;
}

/* 当屏幕宽度小于或等于 450px 时 */
@media (max-width: 500px) {
  .back-button {
    /* 靠近右边界 */
    bottom: auto;
    /* 取消底部定位 */
    bottom: 10px;
    right: 10px;
    /* 靠近顶部 */
    width: 30px;
    /* 尺寸更小 */
    height: 30px;
  }

  .arrow-icon {
    width: 10px;
    height: 10px;
  }
}
</style>
